import { Action } from '@ngrx/store';
import { ExpectedTimeofCompletionDTO } from './model';

export enum DetailedStatusExpectedTimeOfCompletionHistoryActionTypes {
    DetailedStatusExpectedTimeofCompletionHistoryRequest = '[DetailedStatus] Expected Time of Completion History Request',
    DetailedStatusExpectedTimeofCompletionHistorySuccess = '[DetailedStatus] Expected Time of Completion History Success',
    DetailedStatusExpectedTimeofCompletionHistoryError = '[DetailedStatus] Expected Time of Completion History Error',
}

export class DetailedStatusExpectedTimeofCompletionHistoryRequest implements Action {
    readonly type = DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistoryRequest;

    constructor(public payload: { id: number }) {}
}

export class DetailedStatusExpectedTimeofCompletionHistorySuccess implements Action {
    readonly type = DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistorySuccess;

    constructor(public payload: ExpectedTimeofCompletionDTO[]) {}
}

export class DetailedStatusExpectedTimeofCompletionHistoryError implements Action {
    readonly type = DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistoryError;

    constructor(public payload: string) {}
}

export type DetailedStatusActions =
    // | DetailedStatusAddExpectedClosureDateRequest
    // | DetailedStatusAddExpectedClosureDateSuccess
    // | DetailedStatusAddExpectedClosureDateError
    | DetailedStatusExpectedTimeofCompletionHistoryRequest
    | DetailedStatusExpectedTimeofCompletionHistoryError
    | DetailedStatusExpectedTimeofCompletionHistorySuccess
    ;

