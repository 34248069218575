import {
    DetailedStatusState,
    DetailedStatusDataPagination,
    AQVDDetailedStatusFilter,
    BITRDetailedStatusFilter,
    CITRDetailedStatusFilter,
    PLIDetailedStatusFilter,
    RedlineDetailedStatusFilter,
    EXDetailedStatusFilter,
    COWDetailedStatusFilter,
    DetailedStatusTableScrollProgress,
    BITRandCITRDetailedStatusFilter,
    ConstraintDetailsStatusFilter,
    RFSUWorkToGoSearchDetailedStatusFilter,
    RFOWorkToGoSearchDetailedStatusFilter,
} from './model';
import { DetailedStatusActions, DetailedStatusActionTypes } from './actions';
import { ExceptionScope, SubsystemScope, UserDefinedInputs } from 'src/app/enums';
import { DetailedStatusLockedFilter } from 'src/app/models/detailed-status-locked-filter';
import * as _ from 'lodash';

const initialState: DetailedStatusState = {
    dataPagination: new DetailedStatusDataPagination(),
    isLoading: false,
    makeRequest: false,
    AQVD: new AQVDDetailedStatusFilter(),
    BITR: new BITRDetailedStatusFilter(),
    BITRandCITR: new BITRandCITRDetailedStatusFilter(),
    ConstraintDetails: new ConstraintDetailsStatusFilter(),
    CITR: new CITRDetailedStatusFilter(),
    PLI: new PLIDetailedStatusFilter(),
    Redline: new RedlineDetailedStatusFilter(),
    EX: new EXDetailedStatusFilter(),
    COW: new COWDetailedStatusFilter(),
    lockedFilter: null,
    tableOffset: new DetailedStatusTableScrollProgress(),
    RFSUWorkToGoSearch : new RFSUWorkToGoSearchDetailedStatusFilter(),
    RFOWorkToGoSearch : new RFOWorkToGoSearchDetailedStatusFilter(),
};

export function reducer(state: DetailedStatusState = initialState, action: DetailedStatusActions): DetailedStatusState {
    switch (action.type) {
        case DetailedStatusActionTypes.DetailedStatusFilterRequest: {
            return {
                ...state,
                isLoading: true,
                makeRequest: false,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusSetMakeRequest: {
            return {
                ...state,
                makeRequest: true,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusFilterSuccess: {
            return {
                ...state,
                dataPagination: action.payload,
                isLoading: false,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusITRConstraintError:
        case DetailedStatusActionTypes.DetailedStatusAddBulkCommentError:
        case DetailedStatusActionTypes.DetailedStatusAddCommentError:
        case DetailedStatusActionTypes.DetailedStatusAddO3ConstraintError:
        case DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentSuccess :
        case DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentError:
        case DetailedStatusActionTypes.DetailedStatusFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                if (state.lockedFilter) {
                    return {
                        ...state,
                        lockedFilter: {
                            ...state.lockedFilter,
                            sortBy: action.payload.value.active,
                            direction: action.payload.value.direction,
                        },
                    };
                } else {
                    return {
                        ...state,
                        [action.payload.scope]: {
                            ...state[action.payload.scope],
                            sortBy: action.payload.value.active,
                            direction: action.payload.value.direction,
                        },
                    };
                }
            } else {
                if (state.lockedFilter) {
                    return {
                        ...state,
                        lockedFilter: {
                            ...state.lockedFilter,
                            [action.payload.key]: Array.isArray(action.payload.value)
                                ? [...action.payload.value]
                                : action.payload.value,
                        },
                    };
                } else {
                    return {
                        ...state,
                        [action.payload.scope]: {
                            ...state[action.payload.scope],
                            [action.payload.key]: Array.isArray(state[action.payload.scope][action.payload.key])
                                ? [...action.payload.value]
                                : action.payload.value,
                        },
                    };
                }
            }
        }
        case DetailedStatusActionTypes.DetailedStatusFilterMultiplePropertyUpdate: {
            const obj = action.payload.items.reduce((acc, value) => ({ ...acc, [value.key]: value.value }), {});
            if (state.lockedFilter) {
                return {
                    ...state,
                    lockedFilter: {
                        ...state.lockedFilter,
                        ...obj,
                    },
                };
            } else {
                return {
                    ...state,
                    [action.payload.scope]: {
                        ...state[action.payload.scope],
                        ...obj,
                    },
                };
            }
        }
        case DetailedStatusActionTypes.DetailedStatusFilterReset: {
            return {
                ...state,
                [action.payload.scope]: {
                    ...action.payload.filterInstance,
                    sortBy: state[action.payload.scope].sortBy,
                    direction: state[action.payload.scope].direction,
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusSetLoader: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentRequest:
        case DetailedStatusActionTypes.DetailedStatusAddBulkCommentRequest:
        case DetailedStatusActionTypes.DetailedStatusAddITRConstraintRequest:
        case DetailedStatusActionTypes.DetailedStatusUpdateITRConstraintRequest:
        case DetailedStatusActionTypes.DetailedStatusAddCommentRequest:
        case DetailedStatusActionTypes.DetailedStatusAddO3ConstraintRequest:
        case DetailedStatusActionTypes.DetailedStatusAddBulkExceptionCommentRequest:
        case DetailedStatusActionTypes.DetailedStatusRemoveCommentRequest:
        case DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((i) => 
                    (action.payload.scope === SubsystemScope.PLI || action.payload.scope === SubsystemScope.COW) &&
                     action.payload.nbResponsiblePerson !== UserDefinedInputs.ResponsiblePerson 
                    ? i.number === action.payload.entityId
                    : (action.payload.scope === SubsystemScope.ConstraintDetails ? 
                        i.itrConstraintId.toString() === action.payload.entityId : i.id.toString() === action.payload.entityId) 
            );
            const itemExceptionIndex = state.dataPagination.items.findIndex((i) => {
                if(action.payload.exscope !== null  || action.payload.exscope != undefined) {
                  const compareId =  action.payload.exscope === ExceptionScope.PLI ? i.number 
                                    : (action.payload.exscope === ExceptionScope.ChangeDocument ? i.npwId.toString() 
                                    : i.id.toString()) ;  
                 return action.payload.additionaEntityId === compareId && action.payload.entityId === i.exceptionNumber;
                }
            });
            const itemNBresponsiblePersonIndex = state.dataPagination.items.findIndex((i) => {
                if(action.payload.scope === SubsystemScope.PLI && action.payload.nbResponsiblePerson === UserDefinedInputs.ResponsiblePerson ) {
                  const compareId =  action.payload.nbResponsiblePerson === UserDefinedInputs.ResponsiblePerson ?  i.number : i.id.toString() ;  
                 return action.payload.entityId === compareId;
                }
            });
            const itemRFOWorksToGoIndex = state.dataPagination.items.findIndex((i) => {
                if(action.payload.scope === SubsystemScope.RFOWorkToGoSearch && action.payload.rfo !== null ) {
                 const compareId =  i.pli !== null ? i.pli : i.barcode !== null ? i.barcode : i.exceptionNumber !== null ? i.exceptionNumber : i.npw !== null ? i.npw : i.pascr !== null ? i.pascr : i.redline;
                 return action.payload.entityId === compareId && action.payload.rfo === i.rfo  && action.payload.subSystem === i.subsystem;
                }
            });
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                latestComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        if(i === itemExceptionIndex) {
                            return {
                                ...d,
                                latestExceptionComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        if(i === itemNBresponsiblePersonIndex) {
                            return {
                                ...d,
                                latestNBResponsiblePersonComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        if (i === itemRFOWorksToGoIndex) {
                            return {
                                ...d,
                                latestComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddITRResponsibleCommentSuccess : {
            const itemNBresponsiblePersonIndex = state.dataPagination.items.findIndex((i) => {
                if(action.payload.scope === SubsystemScope.AQVD ||
                    action.payload.scope === SubsystemScope.BITR ||
                    action.payload.scope === SubsystemScope.CITR) {
                        const compareId = i.id.toString() ;  
                        return action.payload.entityId === compareId;
                    }
            });
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {                   
                        if(i === itemNBresponsiblePersonIndex) {
                            return {
                                ...d,
                                latestNBResponsiblePersonComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        
                        return d;
                    }),
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddO3ConstraintSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((i) => 
                    i.tagNumber.toString() === action.payload.tagNumber
                    && i.exceptionNumber.toString() === action.payload.exceptionNumber
                    && i.number.toString() === action.payload.pliqvdNumber
                    && i.subsystem.toString() === action.payload.subsystem);
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                o3ConstraintId: action.payload.o3constraint,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d) => ({
                        ...d,
                        latestComment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }        
        case DetailedStatusActionTypes.DetailedStatusSetLockedFilter: {
            return {
                ...state,
                lockedFilter: state.lockedFilter
                    ? { ...state.lockedFilter, ...action.payload }
                    : { ...new DetailedStatusLockedFilter(), ...action.payload },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusResetLockedFilter: {
            return {
                ...state,
                lockedFilter: null,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAttachmentsUpdated: {
            let itemIndex = 0;
            if(action.payload.scope === SubsystemScope.ConstraintDetails)
                itemIndex = state.dataPagination.items.findIndex((i) => i.itrConstraintToITRId === +action.payload.number);
            else           
                itemIndex = state.dataPagination.items.findIndex((i) => i.number === action.payload.number);
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return { ...d, attachments: [...action.payload.attachments] };
                        }
                        return d;
                    }),
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusSetTableOffset: {
            return {
                ...state,
                tableOffset: { ...state.tableOffset, ...action.payload },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddITRConstraintSuccess: {
            var newDetailedStatus = _.cloneDeep(action.payload.itr);
            newDetailedStatus.itrConstraintId = _.cloneDeep(action.constId);
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: [...state.dataPagination.items, newDetailedStatus]
                },
                isLoading: false,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusUpdateITRConstraintSuccess: {
            const newDetailedStatuses = _.cloneDeep(state.dataPagination.items);
            if (action.payload.itrConstraint.deleted === "deleted") {
                const itemIndex = state.dataPagination.items.findIndex((i) => i.itrConstraintId === action.payload.itrConstraint.id);
                if (itemIndex > -1) {
                    newDetailedStatuses.splice(itemIndex, 1);
                } else {
                    return {
                        ...state,
                        isLoading: false,
                    };
                }
            } else {
                newDetailedStatuses.forEach((itr) => {
                if ( itr.itrConstraintId === action.payload.itr.itrConstraintId) {
                    itr.itrConstraintTypeName = action.payload.itrConstraint.constraintTypeName;
                    itr.itrConstraintDueDate = action.payload.itrConstraint.dueDate?.isValid() ? action.payload.itrConstraint.dueDate : null;
                    itr.itrConstraintResponsibleUser = action.payload.itrConstraint.responsibleUserName;
                    itr.fmRorPO = action.payload.itrConstraint.fmrOrPO;
                    itr.waypointRequired = action.payload.itrConstraint.waypointRequired;
                    itr.fmRorPOETADate = action.payload.itrConstraint.fmRorPOETADate?.isValid() ? action.payload.itrConstraint.fmRorPOETADate : null;
                    itr.associatedNPW = action.payload.itrConstraint.associatedNPW;
                    itr.associatedPLI = action.payload.itrConstraint.associatedPLI;
                    itr.associatedQVD = action.payload.itrConstraint.associatedQVD;
                    itr.itrConstraintAdditionalResponsibleUser = action.payload.itrConstraint.additionalResponsibleUserName;
                    itr.itrConstraintDescription = action.payload.itrConstraint.itrConstraintDescription;
                    itr.associatedNPWChangeType = action.payload.itrConstraint.associatedNPWChangeType;
                }
                 
            })

            }
            return { 
                ...state, 
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatuses
                },
                isLoading: false
            };

        } 
        case DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateRequest: {
            const newDetailedStatuses = _.cloneDeep(state.dataPagination.items);
            newDetailedStatuses.forEach((ex) => {
                if ( ex.scope === SubsystemScope.PLI) {  
                    if(ex.number === action.payload.ex.number) {
                        ex.forecastClosureDate = action.payload.forecastClosureDate;
                    }         
                } else{
                    if(ex.id === action.payload.ex.id && ex.number === action.payload.ex.number && ex.exceptionNumber === action.payload.ex.exceptionNumber) {
                        ex.forecastClosureDate = action.payload.forecastClosureDate;
                    }
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatuses
                },
                isLoading: true,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateRequest: {
            const newDetailedStatuses = _.cloneDeep(state.dataPagination.items);
            newDetailedStatuses.forEach((pli) => {
                if ( pli.scope === SubsystemScope.PLI) {  
                    if(pli.number === action.payload.pli.number) {
                        pli.pliForecastClosureDate = action.payload.forecastClosureDate;
                        pli.itrConstraintDueDate = pli.itrConstraintDueDate != null ? action.payload.forecastClosureDate : pli.itrConstraintDueDate;
                    }         
                } else{
                    if(pli.id === action.payload.pli.id) {
                        pli.pliForecastClosureDate = action.payload.forecastClosureDate;
                        pli.itrConstraintDueDate = pli.itrConstraintDueDate != null ? action.payload.forecastClosureDate : pli.itrConstraintDueDate;
                    }
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatuses
                },
                isLoading: true,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateRequest: {
            const newPostRFOWorkData = _.cloneDeep(state.dataPagination.items);
            newPostRFOWorkData.forEach((postRFOWork) => {
                if(postRFOWork.rfo === action.payload.postRfoData.rfo && postRFOWork.subsystem === action.payload.postRfoData.subsystem 
                    && postRFOWork.exceptionNumber == action.payload.postRfoData.exceptionNumber
                    && ((postRFOWork.barcode != null && postRFOWork.barcode == action.payload.postRfoData.barcode) 
                        || (postRFOWork.pli != null && postRFOWork.pli == action.payload.postRfoData.pli)
                        || (postRFOWork.npw != null && postRFOWork.npw == action.payload.postRfoData.npw)
                        || (postRFOWork.pascr != null && postRFOWork.pascr == action.payload.postRfoData.pascr) 
                        || (postRFOWork.redline != null && postRFOWork.redline == action.payload.postRfoData.redline))) {
                        postRFOWork.postRFOWorkExpectedClosureDate = action.payload.expectedClosureDate; 
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newPostRFOWorkData
                },
                isLoading: true,
            };
        }
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPriorityRequest: {
            const newPostRFOWorkData = _.cloneDeep(state.dataPagination.items);
            newPostRFOWorkData.forEach((postRFOWork) => {
                if(postRFOWork.rfo === action.payload.manualPriority.rfo && postRFOWork.subsystem === action.payload.manualPriority.subsystem 
                    && postRFOWork.exceptionNumber == action.payload.manualPriority.exceptionNumber
                    && ((postRFOWork.barcode != null && postRFOWork.barcode == action.payload.manualPriority.barcode) 
                        || (postRFOWork.pli != null && postRFOWork.pli == action.payload.manualPriority.pli)
                        || (postRFOWork.npw != null && postRFOWork.npw == action.payload.manualPriority.npw)
                        || (postRFOWork.pascr != null && postRFOWork.pascr == action.payload.manualPriority.pascr) 
                        || (postRFOWork.redline != null && postRFOWork.redline == action.payload.manualPriority.redline))) {
                        postRFOWork.priorityManualOverride = action.payload.manualPriority.priorityManualOverride; 
                }                       
             });
             return {
                 ...state,
                 dataPagination: {
                     ...state.dataPagination,
                     items: newPostRFOWorkData
                 },
                 isLoading: true,
             };
        }
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupRequest: {
            const newPostRFOWorkData = _.cloneDeep(state.dataPagination.items);
            newPostRFOWorkData.forEach((postRFOWork) => {
                if(postRFOWork.rfo === action.payload.responsiblePersonOrGroup.rfo && postRFOWork.subsystem === action.payload.responsiblePersonOrGroup.subsystem 
                    && postRFOWork.exceptionNumber == action.payload.responsiblePersonOrGroup.exceptionNumber
                    && ((postRFOWork.barcode != null && postRFOWork.barcode == action.payload.responsiblePersonOrGroup.barcode) 
                        || (postRFOWork.pli != null && postRFOWork.pli == action.payload.responsiblePersonOrGroup.pli)
                        || (postRFOWork.npw != null && postRFOWork.npw == action.payload.responsiblePersonOrGroup.npw)
                        || (postRFOWork.pascr != null && postRFOWork.pascr == action.payload.responsiblePersonOrGroup.pascr) 
                        || (postRFOWork.redline != null && postRFOWork.redline == action.payload.responsiblePersonOrGroup.redline))) {
                        postRFOWork.responsiblePersonOrGroup = action.payload.responsiblePersonOrGroup.responsiblePersonOrGroup; 
                }                       
             });
             return {
                 ...state,
                 dataPagination: {
                     ...state.dataPagination,
                     items: newPostRFOWorkData
                 },
                 isLoading: true,
             };
        }
        case DetailedStatusActionTypes.DetailedStatusAddExPriorityRequest: {
            const newDetailedStatuses = _.cloneDeep(state.dataPagination.items);
            newDetailedStatuses.forEach((ex) => {
                if ( ex.scope === SubsystemScope.PLI) {  
                    if(ex.number === action.payload.priority.number) {
                        ex.exceptionPriority = action.payload.priority.exceptionPriority;
                    }         
                } else{
                    if(ex.id === action.payload.priority.id && ex.number === action.payload.priority.number && ex.exceptionNumber === action.payload.priority.exceptionNumber) {
                        ex.exceptionPriority = action.payload.priority.exceptionPriority;
                    }
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatuses
                },
                isLoading: true,
            };
        }

        case DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.number === action.payload.number);
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                pliImplementationTeam : action.payload.implementationTeam                         
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateSuccess :
        case DetailedStatusActionTypes.DetailedStatusAddEXForecastClosureDateError :
        case DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateSuccess :
        case DetailedStatusActionTypes.DetailedStatusAddPLIForecastClosureDateError :
        case DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateError :
        case DetailedStatusActionTypes.DetailedStatusAddPostRFOWorkExpectedClosureDateSuccess :
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPrioritySuccess :
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateManualPriorityError :   
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupSuccess :
        case DetailedStatusActionTypes.DetailedStatusPostRFOWorkUpdateResponsiblePersonOrGroupError :        
        case DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonSuccess :
        case DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonError :  
        case DetailedStatusActionTypes.DetailedStatusAddExPrioritySuccess :
        case DetailedStatusActionTypes.DetailedStatusAddExPriorityError :
        case DetailedStatusActionTypes.DetailedStatusRemoveCommentSuccess :
        case DetailedStatusActionTypes.DetailedStatusRemoveCommentError :
        case DetailedStatusActionTypes.DetailedStatusUpdatePliImplementationTeamError :
        case DetailedStatusActionTypes.DetailedStatusAddExpectedClosureDateSuccess:
        case DetailedStatusActionTypes.DetailedStatusRemoveExceptionCommentError:
            {
                return {
                    ...state,
                    isLoading: false,
                };
            }
        case DetailedStatusActionTypes.DetailedStatusAddResponsiblePersonRequest: {
            const newDetailedStatuses = _.cloneDeep(state.dataPagination.items);
            newDetailedStatuses.forEach((ex) => {
                if ( ex.scope === SubsystemScope.PLI) { 
                    if(action.payload.typeInput === UserDefinedInputs.ResponsiblePerson) {
                        if(ex.number === action.payload.ex.number) {
                            ex.responsiblePerson = action.payload.responsiblePerson;
                            ex.responsiblePersonId = action.payload.responsiblePersonId;
                        }
                    } else {
                        if(ex.number === action.payload.ex.number) {                          
                            ex.exceptionAdditionalResponsibleUser = action.payload.responsiblePerson;
                            ex.exceptionAdditionalResponsibleUserId = action.payload.responsiblePersonId;
                        } 
                    }
                          
                } else {
                    if(action.payload.typeInput === UserDefinedInputs.ResponsiblePerson) {
                        if(ex.id === action.payload.ex.id && ex.number === action.payload.ex.number && ex.exceptionNumber === action.payload.ex.exceptionNumber) {
                            ex.responsiblePerson = action.payload.responsiblePerson;
                            ex.responsiblePersonId = action.payload.responsiblePersonId;
                        }
                    } else {
                        if(ex.id === action.payload.ex.id && ex.number === action.payload.ex.number && ex.exceptionNumber === action.payload.ex.exceptionNumber) {                          
                            ex.exceptionAdditionalResponsibleUser = action.payload.responsiblePerson;
                            ex.exceptionAdditionalResponsibleUserId = action.payload.responsiblePersonId;
                        }
                    }
                  
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatuses
                },
                isLoading: true,
            };
        }

        case DetailedStatusActionTypes.DetailedStatusAddExpectedClosureDateRequest: {
            const newDetailedStatusData = _.cloneDeep(state.dataPagination.items);
            newDetailedStatusData.forEach((detailedStatus) => {
                if(detailedStatus.id === action.payload.detailedStatusData.id) {
                    detailedStatus.expectedTimeOfCompletion = action.payload.expectedClosureDate; 
                }
            });
            return {
                ...state,
                dataPagination: {
                    ...state.dataPagination,
                    items: newDetailedStatusData
                },
                isLoading: true,
            };
        }

        default: {
            return state;
        }
    }
}
