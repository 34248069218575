import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    DetailedStatusExpectedTimeOfCompletionHistoryActionTypes,
    DetailedStatusExpectedTimeofCompletionHistoryError,
    DetailedStatusExpectedTimeofCompletionHistoryRequest,
    DetailedStatusExpectedTimeofCompletionHistorySuccess,
} from './actions';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { ToastService } from 'src/app/services/shared/toast.service';
import { DetailedStatusService } from 'src/app/services/api/webapi-services/detailed-status.service';
import { SubsystemScope } from 'src/app/enums';
import * as _ from 'lodash';

@Injectable()
export class DetailedStatusExpectedTimeOfCompletionHistoryEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private toastService: ToastService,
        private detailedStatusService: DetailedStatusService,
    ) {}
    subsystemScopes = SubsystemScope;
   
    @Effect()
    getRedlineExpectedTimeOfCompletionHistory$ = this.actions$.pipe(
        ofType<DetailedStatusExpectedTimeofCompletionHistoryRequest>(DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistoryRequest),
        mergeMap((action) => {
            return this.detailedStatusService.getRedlineExpectedTimeofCompletionHistory(action.payload.id)
                .pipe(
                    map((data) => new DetailedStatusExpectedTimeofCompletionHistorySuccess(data)),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while getting Expected Time of Completion history. Please contact Program Administrator.'
                        );
                        return of(new DetailedStatusExpectedTimeofCompletionHistoryError(error));
                    })
                );
            }
        )
    );

}
