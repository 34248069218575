import { UserDetail, AppRole } from '../store/common.model';

export class UserToContractsDTO {
    id: number;
    user: UserDetail;
    userRole: AppRole;
    contracts: string[];
    isInEditMode: boolean;
    prevContracts: string[];
    prevUserRole: AppRole;
    prevUser: UserDetail;
}
