import { DetailedStatusActions, DetailedStatusExpectedTimeOfCompletionHistoryActionTypes } from './actions';
import {
    ExpectedTimeOfCompletionHistoryState
} from './model';

const initialState: ExpectedTimeOfCompletionHistoryState= {
    isLoading: false,
    etcData : [],
};


export function reducer(state: ExpectedTimeOfCompletionHistoryState = initialState, action: DetailedStatusActions): ExpectedTimeOfCompletionHistoryState {
    switch (action.type) {
        case DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistoryRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistorySuccess: {
            return {
                ...state,
                etcData: action.payload,
                isLoading: false
            };
        }
        case DetailedStatusExpectedTimeOfCompletionHistoryActionTypes.DetailedStatusExpectedTimeofCompletionHistoryError: {
            return {
                ...state,
                etcData: [],
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}
