import { ExceptionScope, SubsystemScope, Zone } from '../enums';
import {
    CalendarColumn,
    NumericColumn,
    CheckListColumn,
    McMilestone,
    OrderDirection,
    RFO,
    StagedStartUpPriority,
    TCOUser,
} from '../store/common.model';
import { ShowHideColumns } from '../store/detailed-status/model';

export class DetailedStatusLockedFilter {
    itrid = 0;
    scope: SubsystemScope;
    subsystem: string;
    discipline: string;
    priority: string;
    category: string[];
    zone: Zone;
    rfo: string;
    page = 0;
    take = 25;
    sortBy: string;
    direction = OrderDirection.Desc;
    exScope = ExceptionScope.ITR;
    projectTeams: string[];
    mcMilestones: McMilestone[];
    rfos: RFO[] = [];
    mcEngineer: TCOUser[];
    systemOwner: TCOUser[];
    goocs: { no: string }[];
    stagedStartUpPriorities: StagedStartUpPriority[] = [];
    excludeExceptions: string[];
    columnSubsystem: CheckListColumn = null;
    columnOriginalSubsystem: CheckListColumn = null;
    columnNumber: CheckListColumn = null;
    columnDescription: CheckListColumn = null;
    columnSubsystemName: CheckListColumn = null;
    columnTagNumber: CheckListColumn = null;
    columnTagType: CheckListColumn = null;
    columnTagName: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnMaterialReference: CheckListColumn = null;
    ColumnAttachmentName:CheckListColumn = null;
    columnClassification: CheckListColumn = null;
    columnCategory: CheckListColumn = null;
    columnActionBy: CheckListColumn = null;
    columnActionGroup: CheckListColumn = null;
    columnDeliveryEngineer: CheckListColumn = null;
    columnPliImplementationTeam: CheckListColumn = null;
    columnPunchType: CheckListColumn = null;
    columnReviewStatusComplete: CheckListColumn = null;
    columnACNumber: CheckListColumn = null;
    columnRaisedBy: CheckListColumn = null;
    columnTagDiscipline: CheckListColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnRequiredByDate: CalendarColumn = null;
    columnRevision: CheckListColumn = null;
    columnDocType: CheckListColumn = null;
    columnDocType2: CheckListColumn = null;
    columnRedType: CheckListColumn = null;
    columnRedReqType: CheckListColumn = null;
    columnChangeId: CheckListColumn = null;
    columnRedStatus: CheckListColumn = null;
    columnContainsChange: CheckListColumn = null;
    columnMcdrConstrRedCat: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnExceptionPhase: CheckListColumn = null;
    columnExceptionStatus: CheckListColumn = null;
    columnTargetCompletionDate: CalendarColumn = null;
    columnLastConstraintDate: CalendarColumn = null;
    columnForecastClosureDate: CalendarColumn = null;
    columnComments: CheckListColumn = null;
    columnCowDocNumber: CheckListColumn = null;
    columnBarcode: CheckListColumn = null;
    showHideColumns = new ShowHideColumns();
    columnPreCommStart: CalendarColumn = null;
    columnPreCommFinish: CalendarColumn = null;
    columnStatusDate: CalendarColumn = null;
    columnMcPlan: CalendarColumn = null;
    columnMcForecast: CalendarColumn = null;
    columnRfsuPlan: CalendarColumn = null;
    columnRfsuForecast: CalendarColumn = null;
    columnPriority: CheckListColumn = null;
    columnExceptionPriority: CheckListColumn = null;
    columnItrInCurrentWeek: CheckListColumn = null;
    columnConstraintFlag: CheckListColumn = null;
    columnActivityID: CheckListColumn = null;
    columnActivityName: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOPlan: CalendarColumn = null;
    columnMCActual: CalendarColumn = null;
    columnRFSUActual: CalendarColumn = null;
    columnWaypoint: CheckListColumn = null;
    columnWaypointDescription: CheckListColumn = null;
    columnAreaBreakdown: CheckListColumn = null;
    columnWaypointTargetDate: CalendarColumn = null;
    columnMilestone: CheckListColumn = null;
    columnMilestoneDescription: CheckListColumn = null;
    columnMilestoneTargetDate: CalendarColumn = null;
    columnWorkAreaName: CheckListColumn = null;
    columnWorkOrderNum: CheckListColumn = null;
    columnLatestITRComment: CheckListColumn = null;
    columnAssociatedComments: CheckListColumn = null;
    columnAQVDContractor: CheckListColumn = null;
    ColumnPLIActionedBy: CheckListColumn = null;
    columnLatestExceptionComment: CheckListColumn = null;
    columnO3ConstraintID: CheckListColumn = null;
    columnITRConstraintTypeName: CheckListColumn = null;
    columnITRConstraintResponsibleUser: CheckListColumn = null;
    columnITRConstraintResponsibleGroup: CheckListColumn = null;
    columnITRConstraintAdditionalResponsibleUser: CheckListColumn = null;
    columnITRConstraintRaisedByUser: CheckListColumn = null;
    columnITRConstraintRaisedByTeam: CheckListColumn = null;
    columnITRConstraintDueDate: CalendarColumn = null;
    columnFMRorPOETADate: CalendarColumn = null;
    columnITRConstraintRaisedDate: CalendarColumn = null;
    columnITRConstraintStatusDate: CalendarColumn = null;
    columnRFSUWalkdownForecast: CalendarColumn = null;
    columnFMRorPO: CheckListColumn = null;
    columnWaypointRequired: CheckListColumn = null;
    columnAssociatedNPW: CheckListColumn = null;
    columnAssociatedPLI: CheckListColumn = null;
    columnAssociatedQVD: CheckListColumn = null;
    columnITRConstraintDescription: CheckListColumn = null;
    columnITRConstraintStatus: CheckListColumn = null;
    columnITRConstraintId: CheckListColumn = null;
    columnITRConstraintCount: NumericColumn = null;
    columnMaterialsTotalCount: NumericColumn = null;
    columnManhours : NumericColumn = null;
    columnPriorityNo : CheckListColumn = null;
    columnCriticalPath : CheckListColumn = null;
    columnPLIForecastClosureDate : CalendarColumn = null;
    columnETA: CalendarColumn = null;
    columnPli: CheckListColumn = null;
    columnNpw: CheckListColumn = null;
    columnPascr:CheckListColumn = null;
    columnRedline: CheckListColumn = null;
    columnPostRFOWorkExpectedClosureDate: CalendarColumn = null;
    columnNBResponsiblePersonComments: CheckListColumn = null;
    columnRFOActual: CalendarColumn = null;
    columnQVDNumber: CheckListColumn = null;
    columnPliCreatedDate: CalendarColumn = null;
    columnNpwIssueDate: CalendarColumn = null;
    columnItemCreatedDate: CalendarColumn = null;
    columnResponsiblePersonOrGroup: CheckListColumn = null;
    columnItemComment: CheckListColumn = null;
    columnPriorityManualOverride: CheckListColumn = null;
    columnRedlineExceptionNumber: CheckListColumn = null;
    columnETransferNumber: CheckListColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
}
