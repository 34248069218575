/* tslint:disable:max-line-length */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { UserSynchronizationComponent } from './components/user-synchronization/user-synchronization.component';
import { StoreModule } from './store/store.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupService } from './services/shared/popup.service';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { RedZoneComponent } from './components/redzone/redzone.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DetailedStatusComponent } from './components/detailed-status/detailed-status.component';
import { DetailsComponent } from './components/details/details.component';
import { ChangeManagementComponent } from './components/change-management/change-management.component';
import { BlueZoneComponent } from './components/bluezone/bluezone.component';
import { ChangeDocumentComponent } from './components/admin-section/change-document/change-document.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { SubsystemConflictsComponent } from './components/admin-section/subsystem-conflicts/subsystem-conflicts.component';
import { BreakLinePipe } from './pipes/break-line.pipe';
import { ManualUploadComponent } from './components/admin-section/manual-upload/manual-upload.component';
import { MasterSubsystemListComponent } from './components/admin-section/manual-upload/master-subsystem-list/master-subsystem-list.component';
import { CWPRegisterComponent } from './components/admin-section/manual-upload/cwp-register/cwp-register.component';
import { ChangeDocumentDetailsComponent } from './components/change-document-details/change-document-details.component';
import { RoleService } from './services/shared/role.service';
import { ExceptionComponent } from './components/admin-section/automatic-upload/exceptions/exceptions.component';
import { AutomaticUploadComponent } from './components/admin-section/automatic-upload/automatic-upload.component';
import { CertificateComponent } from './components/admin-section/automatic-upload/certificate/certificate.component';
import { ITRComponent } from './components/admin-section/automatic-upload/itr/itr.component';
import { PLIComponent } from './components/admin-section/automatic-upload/pli/pli.component';
import { TagComponent } from './components/admin-section/automatic-upload/tag/tag.component';
import { ProjectTeamsService } from './services/shared/project-teams.service';
import { FieldChangeSummaryComponent } from './components/change-management/field-change-summary/field-change-summary.component';
import { DataMaintenanceComponent } from './components/admin-section/data-maintenance/data-maintenance.component';
import { SignOffPopupComponent } from './components/change-document-details/sign-off-popup/sign-off-popup.component';
import { WeeklyPlanningComponent } from './components/weekly-planning/weekly-planning.component';
import { WeeklyPlanningDashboardComponent } from './components/weekly-planning/dashboard/weekly-planning-dashboard.component';
import { WeeklyPlanningSessionComponent } from './components/weekly-planning/session/weekly-planning-session.component';
import { WeeklyPlanningSessionMCComponent } from './components/weekly-planning/session/mc/weekly-planning-session-mc.component';
import { WeeklyPlanningSessionRFSUComponent } from './components/weekly-planning/session/rfsu/weekly-planning-session-rfsu.component';
import { WeeklyPlanningSessionFilterComponent } from './components/weekly-planning/shared/filter/weekly-planning-session-filter.component';
import { WeeklyPlanningSessionTableComponent } from './components/weekly-planning/shared/table/weekly-planning-session-table.component';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { ChangeValidationComponent } from './components/change-validation/change-validation.component';
import { HistoryPopupComponent } from './components/validation-form/history-popup/history-popup.component';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { PopoverModule } from 'ngx-smart-popover';
import { TeamDataComponent } from './components/admin-section/data-maintenance/team-data/team-data.component';
import { WeeklyPlanningPastWeekComponent } from './components/weekly-planning/session/past-week/weekly-planning-past-week.component';
import { WeeklyPlanningCommitmentsComponenet } from './components/weekly-planning/commitments/weekly-planning-commitments.component';
import { AddContractorPopupComponent } from './components/admin-section/data-maintenance/team-data/contractors-maintenance-component/add-popup/add-contractor-popup.component';
import { CommentsHistoryComponent } from './components/comments-history/comments-history.component';
import { ContractorsMaintenanceComponent } from './components/admin-section/data-maintenance/team-data/contractors-maintenance-component/contractors-maintenance.component';
import { ProjectTeamRolesMaintenanceComponent } from './components/admin-section/data-maintenance/team-data/project-team-roles-maintenance-component/project-team-roles-maintenance.component';
import { AddProjectTeamRolePopupComponent } from './components/admin-section/data-maintenance/team-data/project-team-roles-maintenance-component/add-popup/add-project-team-role-popup.component';
import { SubsystemMaintenanceComponent } from './components/admin-section/data-maintenance/subsystem-data/subsystem-maintenance.component';
import { WeeklyPlanningDailyComponent } from './components/weekly-planning/daily/weekly-planning-daily.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from './modules/shared/shared.module';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsDashboardComponent } from './components/reports/dashboard/reports-dashboard.component';
import { UploadDcnImpactedTagsComponent } from './components/admin-section/upload-dcn-impacted-tags/upload-dcn-impacted-tags.component';
import { ImpactedTagsPopupComponent } from './components/admin-section/impacted-tags-popup/impacted-tags-popup.component';
import { ReportsPlanningLookaheadComponent } from './components/reports/planning/lookahead/reports-planning-lookahead.component';
import { ReportsPlanningLookaheadContentComponent } from './components/reports/planning/lookahead/content/reports-planning-lookahead-content.component';
import { ReportsPlanningLookaheadChartComponent } from './components/reports/planning/lookahead/content/chart/reports-planning-lookahead-chart.component';
import { ReportsPlanningLookaheadTableComponent } from './components/reports/planning/lookahead/content/table/reports-planning-lookahead-table.component';
import { ReportsRundownComponent } from './components/reports/rundown/reports-rundown.component';
import { ReportsRundownContentComponent } from './components/reports/rundown/content/reports-rundown-content.component';
import { ReportsRundownChartComponent } from './components/reports/rundown/content/chart/reports-rundown-chart.component';
import { AssignProjectTeamPopupComponent } from './components/validation-form/assign-project-team-popup/assign-project-team-popup.component';
import { ReportsPlanningCommitmentReliabilityComponent } from './components/reports/planning/commitment-reliability/reports-planning-commitment-reliability.component';
import { ReportsPlanningCommitmentReliabilityContentComponent } from './components/reports/planning/commitment-reliability/content/reports-planning-commitment-reliability-content.component';
import { ReportsPlanningCommitmentReliabilityChartComponent } from './components/reports/planning/commitment-reliability/content/chart/reports-planning-commitment-reliability-chart.component';
import { ReportsPlanningCommitmentReliabilityTableComponent } from './components/reports/planning/commitment-reliability/content/table/reports-planning-commitment-reliability-table.component';
import { ColResizeDirective } from './directives/col-resize.directive';
import { ConfigurationComponent } from './components/admin-section/scmt/configuration.component';
import { TopHeaderTableComponent } from './components/weekly-planning/shared/table/top-header-table.component';
import { IcicleChartComponent } from './components/reports/icicle/content/chart/icicle-chart.component';
import { IcicleReportComponent } from './components/reports/icicle/icicle-report.component';
import { IcicleLineSeriesComponent } from './components/reports/icicle/content/helper/icicle-line-series';
import { IcicleCircleSeriesComponent } from './components/reports/icicle/content/helper/icicle-circle-series';
import { ContractorsMappingsComponent } from './components/admin-section/change-document/contractors-mappings-component/contractors-mappings.component';
import { AddContractorMappingPopupComponent } from './components/admin-section/change-document/contractors-mappings-component/add-popup/add-contractor-mapping-popup.component';
import { ReportsHealthCleanlinessComponent } from './components/reports/health/cleanliness/reports-health-cleanliness.component';
import { IcicleTooltipArea } from './components/reports/icicle/content/helper/icicle-tooltip-area';
import { ReportsHealthCleanlinessContentComponent } from './components/reports/health/cleanliness/content/reports-health-cleanliness-content.component';
import { ProjectTeamMaintenanceComponent } from './components/admin-section/data-maintenance/project-team-data/project-team-maintenance.component';
import { AddProjectTeamPopupComponent } from './components/admin-section/data-maintenance/project-team-data/add-popup/add-project-team-popup.component';
import { GroupVerticalStackedComponent } from './components/reports/rundown/group-vertical-stacked/group-vertical-stacked.component';
import { MilestoneMaintenanceComponent } from './components/admin-section/data-maintenance/milestone-waypoint/milestone-maintenance/milestone-maintenance.component';
import { WaypointMaintenanceComponent } from './components/admin-section/data-maintenance/milestone-waypoint/waypoint-maintenance/waypoint-maintenance.component';
import { MilestoneWaypointComponent } from './components/admin-section/data-maintenance/milestone-waypoint/milestone-waypoint.component';
import { AddMilestonePopupComponent } from './components/admin-section/data-maintenance/milestone-waypoint/milestone-maintenance/add-popup/add-milestone-popup.component';
import { AddWaypointPopupComponent } from './components/admin-section/data-maintenance/milestone-waypoint/waypoint-maintenance/add-popup/add-waypoint-popup.component';
import { ReportsHealthCleanlinessChartComponent } from './components/reports/health/cleanliness/content/chart/reports-health-cleanliness-chart.component';
import { ReportsHealthCleanlinessTableComponent } from './components/reports/health/cleanliness/content/table/reports-health-cleanliness-table.component';
import { TagSearchComponent } from './components/tag-search/tag-search.component';
import { IcicleChartMainComponent } from './components/reports/icicle/content/icicle-chart-main/icicle-chart-main.component';
import { MilestoneDashboardComponent } from './components/reports/milestone-dashboard/milestone-dashboard.component';
import { TableTotalCompleteComponent } from './components/reports/milestone-dashboard/table-total-complete/table-total-complete.component';
import { ClearableMatDatepickerComponent } from './components/clearable-mat-datepicker/clearable-mat-datepicker.component';
import { IwpListingComponent } from './components/admin-section/manual-upload/iwp-listing/iwp-listing.component';
import {
    MsalModule,
    MsalInterceptor,
    MsalService,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuard,
    MsalBroadcastService,
    MsalRedirectComponent,
} from '@azure/msal-angular';
import { ChangeValidationDashboardComponent } from './components/reports/change-validation-dashboard/change-validation-dashboard.component';
import { WeeklyValidationProgressComponent } from './components/reports/change-validation-dashboard/weekly-validation-progress/weekly-validation-progress.component';
import { MonthlyValidationProgressComponent } from './components/reports/change-validation-dashboard/monthly-validation-progress/monthly-validation-progress.component';
import { CumulativeValidationProgressComponent } from './components/reports/change-validation-dashboard/cumulative-validation-progress/cumulative-validation-progress.component';
import { WeeklyCumulativeValidationProgressComponent } from './components/reports/change-validation-dashboard/cumulative-validation-progress-weekly/cumulative-validation-progress.component';
import { ValidationProgressComponent } from './components/reports/change-validation-dashboard/validation-progress/validation-progress.component';
import { SystemisationProgressComponent } from './components/reports/change-validation-dashboard/systemisation-progress/systemisation-progress.component.';
import { MonthlyChangeRaisedComponent } from './components/reports/change-validation-dashboard/monthly-change-raised/monthly-change-raised.component';
import { WeeklyChangeRaisedComponent } from './components/reports/change-validation-dashboard/weekly-change-raised/weekly-change-raised.component';
import { ValidatorPerformanceScoreboardComponent } from './components/reports/change-validation-dashboard/validator-performance-scoreboard/validator-performance-scoreboard.component';
import { RundownCurvesComponent } from './components/reports/milestone-dashboard/rundown-curves/rundown-curves.component';
import { CacheInterceptor } from './interceptors/cache-interceptor';
import { ProgressBarDirective } from './directives/progressbar.directive';
import { MatSelectRemoveAriaOwnsDirective } from './directives/matselectmod.directive';
import { IwpPopupComponent } from './components/change-document-details/iwp-popup/iwp-popup.component';
import { NgxPrintModule } from 'ngx-print';
import { EnumKeyValueListPipe } from './pipes/enum-key-value-list-pipe';
import { AddNewChangeComponent } from './components/change-validation/addNewChange/add-new-change.component';
import { MCPlusComponent } from './components/admin-section/change-document/mcplus/mcplus.component';
import { ReportsSkylineComponent } from './components/reports/skyline/reports-skyline.component';
import { ReportsSkylineChartComponent } from './components/reports/skyline/chart/reports-skyline-chart.component';
import { RFOComponent } from './components/rfo/rfo.component';
import { RFOImportComponent } from './components/admin-section/manual-upload/rfo/rfo.component';
import { SkylinePrintComponent } from './components/reports/skyline/print/skyline-print.component';
import { PortalModule } from '@angular/cdk/portal';
import { SkylineSimpleComponent } from './components/reports/skyline/simple/skyline-simple.component';
import { FilterHiddenPipe } from './pipes/filter-hidden.pipe';
import { AddUserToContractsPopupComponent } from './components/admin-section/data-maintenance/user-to-contracts/add-user-to-contracts-popup/add-user-to-contracts-popup.component';
import { UserToContractsComponent } from './components/admin-section/data-maintenance/user-to-contracts/user-to-contracts.component';
import { UserListPipe } from './pipes/userlist.pipe';
import { ContractorListPipe } from './pipes/contractorlist.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { IMDBComponent } from './components/admin-section/automatic-upload/imdb/imdb.component';
import { WeeklyPlanningSessionRFOComponent } from './components/weekly-planning/session/rfo/weekly-planning-session-rfo.component';
import { GOOC1Component } from './components/reports/milestone-dashboard/gooc1/gooc1.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { SubsystemMCDashboardComponent } from './components/reports/milestone-dashboard/subsystem-mc-dashboard/subsystem-mc-dashboard.component';
import { FormatMentionsPipe } from './pipes/format-mentions.pipe';
import { MyPageComponent } from './components/my-page/my-page.component';
import { ExportPageComponent } from './components/export-page/export-page.component';
import { SystemizedDrawingsComponent } from './components/admin-section/manual-upload/systemized-drawings/systemized-drawings.component';
import {
    appConfig,
    appInsightsConfig,
    APP_INSIGHTS_CONFIG,
    MSALGuardConfigFactory,
    MSALInscanceFactory,
    MSALInterceptorConfigFactory,
} from './app.config';
import { ReplaceCharPipe } from './pipes/replace-char.pipe';
import { NoBreakingDashPipe } from './pipes/no-breaking-dash.pipe';
import { AttachmentsPipe } from './pipes/attachments.pipe';
import { LoopComponent } from './components/admin-section/automatic-upload/loop/loop.component';
import { LoopStatusComponent } from './components/loop-status/loop-status.component';
import { SystemisedDrawingComponent } from './components/systemised-drawing/systemised-drawing.component';
import { GOOC2Component } from './components/reports/milestone-dashboard/gooc2/gooc2.component';
import { COWListComponent } from './components/admin-section/manual-upload/cow-list/cow-list.component';
import { GoocPreMCComponent } from './components/reports/milestone-dashboard/pre-mc/pre-mc.component';
import { EarlyWorksComponent } from './components/reports/milestone-dashboard/pre-mc/early-works/early-works.component';
import { EarlyWalkdownsComponent } from './components/reports/milestone-dashboard/pre-mc/early-walkdowns/early-walkdowns.component';
import { CertificationStatusComponent } from './components/reports/milestone-dashboard/pre-mc/certification-status/certification-status.component';
import { SOCPlanComponent } from './components/reports/milestone-dashboard/pre-mc/soc-plan/soc-plan.component';
import { PreMCSkylineComponent } from './components/reports/milestone-dashboard/pre-mc/skyline/skyline.component';
import { PreMCScheduleComponent } from './components/reports/milestone-dashboard/pre-mc/schedule/schedule.component';
import { SubsystemMCDashboard2Component } from './components/reports/milestone-dashboard/subsystem-mc-dashboard2/subsystem-mc-dashboard2.component';
import { MCScheduleComponent } from './components/reports/milestone-dashboard/mc/schedule/schedule.component';
import { MCSkylineComponent } from './components/reports/milestone-dashboard/mc/skyline/skyline.component';
import { MCLookaheadComponent } from './components/reports/milestone-dashboard/mc/lookahead/lookahead.component';
import { RFSULookaheadComponent } from './components/reports/milestone-dashboard/rfo/lookahead/lookahead.component';
import { DailyPlanningSessionFilterComponent } from './components/weekly-planning/shared/filter/daily-planning-session-filter/daily-planning-session-filter.component';
import { MCSubSystemTableComponent } from './components/reports/milestone-dashboard/mc/sub-system/sub-system.component';
import { ResizableModule } from 'angular-resizable-element';
import { SkylineLegendComponent } from './components/reports/skyline/legend/skyline-legend.component';
import { DeleteSignOffPopupComponent } from './components/change-document-details/delete-sign-off-popup/delete-sign-off-popup.component';
import { CTARegisterComponent } from './components/cta/cta-register.component';
import { OverdueDPLIComponent } from './components/reports/health/overdue-dpli/overdue-dpli.component';
import { RFSUSkylineComponent } from './components/reports/milestone-dashboard/rfo/skyline/skyline.component';
import { CertificateSearchComponent } from './components/certificate/certificate.component';
import { FlangeManagementComponent } from './components/admin-section/manual-upload/flange-management/flange-management.component';
import { RedlineComponent } from './components/admin-section/manual-upload/redline/redline.component';
import { GeneralAppConfigurationComponent } from './components/admin-section/scmt/general-app/general-app-configuration.component';
import { FtpConfigurationComponent } from './components/admin-section/scmt/ftp-configuration/ftp-configuration.component';
import { ChangeManagementManualComponent } from './components/admin-section/scmt/change-management-manual/change-management-manual.component';
import { ConfigurationPanelComponent } from './components/admin-section/scmt/configuration-panel/configuration-panel.component';
import { DesignStatusMappingComponent } from './components/admin-section/scmt/configuration-panel/design-status-mapping/design-status-mapping.component';
import { ImplementationStatusMappingComponent } from './components/admin-section/scmt/configuration-panel/implementation-status-mapping/implementation-status-mapping.component';
import { AddDesignStatusMappingComponent } from './components/admin-section/scmt/configuration-panel/design-status-mapping/add-design-status-mapping/add-design-status-mapping.component';
import { AddImplementationStatusMappingComponent } from './components/admin-section/scmt/configuration-panel/implementation-status-mapping/add-implementation-status-mapping/add-implementation-status-mapping.component';
import { ContractorMappingComponent } from './components/admin-section/scmt/configuration-panel/contractor-mapping/contractor-mapping.component';
import { AddContractorMappingComponent } from './components/admin-section/scmt/configuration-panel/contractor-mapping/add-contractor-mapping/add-contractor-mapping.component';
import { AutomatedFileTransfersMcplusComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/automated-file-transfers-mcplus.component';
import { DisciplineMappingComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/configuration-panel-mcplus/discipline-mapping/discipline-mapping.component';
import { ProjectTeamMappingComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/configuration-panel-mcplus/project-team-mapping/project-team-mapping.component';
import { ConfigurationPanelMCplusComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/configuration-panel-mcplus/configuration-panel-mcplus.component';
import { AddDisciplineMappingComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/configuration-panel-mcplus/discipline-mapping/add-discipline-mapping/add-discipline-mapping.component';
import { AddProjectTeamMappingComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/configuration-panel-mcplus/project-team-mapping/add-project-team-mapping/add-project-team-mapping.component';
import { SourceFileConfigurationComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/source-file-configuration/source-file-configuration.component';
import { SubsystemRfsuDashboardComponent } from './components/reports/milestone-dashboard/subsystem-rfsu-dashboard/subsystem-rfsu-dashboard.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdateService } from './services/api/webapi-services/prompt-update.service';
import { HomeComponent } from './components/home/home.component';
import { CTAFormComponent } from './components/admin-section/manual-upload/cta/cta-form.component';
import { ReportsRundownTableComponent } from './components/reports/rundown/content/table/reports-rundown-table.component';
import { PlanningUploadComponent } from './components/weekly-planning/planning-upload/planning-upload.component';
import { PlanningDataComponent } from './components/weekly-planning/planning-upload/planning-data/planning-data.component';
import { ScheduleActivityComponent } from './components/weekly-planning/planning-upload/schedule-activity/schedule-activity.component';
import { ActivityBarcodeComponent } from './components/weekly-planning/planning-upload/activity-barcode/activity-barcode.component';
import { ExceptionsPriorityComponent } from './components/weekly-planning/planning-upload/exceptions-priority/exceptions-priority.component';
import { ITRManhoursComponent } from './components/weekly-planning/planning-upload/itr-manhours/itr-manhours.component';
import { SCWorkAreaConfigurationComponent } from './components/weekly-planning/sc-work-area-configuration/sc-work-area-configuration.component';
import { ScheduleActivityPlanningComponent } from './components/weekly-planning/sc-planning/lookahead/schedule-activity-planning.component';
import { ScheduleActivityPlanningTableComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/schedule-activity-planning-table.component';
import { ScheduleActivityPlanningSubsystemTableComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/subsystem-activities-table/schedule-activity-planning-subsystem-table.component';
import { ScPlanningConfigurationComponent } from './components/weekly-planning/sc-planning-configuration/sc-planning-configuration.component';
import { ConstraintConfigurationComponent } from './components/weekly-planning/sc-planning-configuration/constraint-configuration/constraint-configuration.component';
import { AddScheduleActivityConstraintComponent } from './components/weekly-planning/sc-planning-configuration/constraint-configuration/add-schedule-activity-constraint-component/add-schedule-activity-constraint.component';
import { AutomatedFileTransfersOtherSourcesComponent } from './components/admin-section/scmt/automated-file-transfers-other-sources/automated-file-transfers-other-sources.component';
import { ConstraintTypeSelectorPopupComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/subsystem-activities-table/constraint-type-selector/constraint-type-selector.component';
import { ITRConstraintTypeSelectorPopupComponent } from 'src/app/components/detailed-status/constraint-type-selector/constraint-type-selector.component';
import { CleanlinessConfigurationComponent } from './components/admin-section/scmt/cleanliness-configuration/cleanliness-configuration.component';
import { ForecastHistoryComponent } from './components/forecast-history/forecast-history.component';
import { ScheduleActivityItrComponent } from './components/schedule-activity-itr/schedule-activity-itr.component';
import { ScheduleActivityLookaheadTableComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/schedule-activity-lookahead-table/schedule-activity-lookahead-table.component';
import { CreateActivityComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/create-activity/create-activity.component';
import { ScheduleActivityPrintComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/subsystem-activities-table/print/schedule-activity-print.component';
import { AddSubsystemComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/add-subsystem/add-subsystem.component';
import { ChipListComponent } from './modules/shared/components/chip-list/chip-list.component';
import { LookaheadWorkAreaFilterComponent } from './components/weekly-planning/sc-planning/lookahead/lookahead-filter/lookahead-work-area-filter/lookahead-work-area-filter.component';
import { LookaheadActivitiesFilterComponent } from './components/weekly-planning/sc-planning/lookahead/lookahead-filter/lookahead-activities-filter/lookahead-activities-filter.component';
import { LookaheadFilterComponent } from './components/weekly-planning/sc-planning/lookahead/lookahead-filter/lookahead-filter.component';
import { LookaheadFilterCommonSectionComponent } from './components/weekly-planning/sc-planning/lookahead/lookahead-filter/lookahead-filter-common-section/lookahead-filter-common-section.component';
import { LookaheadFilterTopHeaderSectionComponent } from './components/weekly-planning/sc-planning/lookahead/lookahead-filter/lookahead-filter-top-header-section/lookahead-filter-top-header-section.component';
import { WeekSummaryComponent } from './components/weekly-planning/sc-planning/lookahead/planning-table/week-summary/week-summary/week-summary.component';
import { MatTableStickyHeaderComponent } from './components/shared/mat-table-sticky-header/mat-table-sticky-header.component';
import { BacklogCategoriesConfigurationComponent } from './components/admin-section/scmt/backlog-categories/backlog-categories.component';
import { PendingChangesGuard } from 'src/guards/pending-changes.guard';
import { OverdueExceptionsComponent } from './components/reports/health/overdue-exceptions/overdue-exceptions.component';
import { OverdueExceptionsFilterComponent } from './components/reports/health/overdue-exceptions/overdue-exceptions-filters/overdue-exceptions-filter.component';
import { OverdueExceptionsChartComponent } from './components/reports/health/overdue-exceptions/overdue-exceptions-chart/overdue-exceptions-chart.component';
import { OverdueExceptionsTableComponent } from './components/reports/health/overdue-exceptions/overdue-exceptions-table/overdue-exceptions-table.component';
import { CustomGroupedVerticalStackedChartComponent } from './components/charts/grouped-vertical-stacked/custom-grouped-vertical-stacked-chart/custom-grouped-vertical-stacked-chart.component';
import { ExcelExportListComponent } from './components/excel-export-list/excel-export-list.component';
import { ScPriorityConfigurationComponent } from './components/weekly-planning/sc-priority-configuration/sc-priority-configuration.component';
import { ConstraintflagComponent } from './components/admin-section/scmt/constraintflag/constraintflag.component';
import { TimeTriggersConfigurationComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/automatic-uploads/time-triggers/time-triggers.component';
import { AutomaticUploadsConfigurationComponent } from './components/admin-section/scmt/automated-file-transfers-mcplus/automatic-uploads/automatic-uploads.component';
import { TraceHeatingStatusComponent } from './components/reports/trace-heating-status/trace-heating-status.component';
import { TraceHeatingStatusTableComponent } from './components/reports/trace-heating-status/trace-heating-status-table/trace-heating-status-table.component';
import { TraceHeatingStatusPrintComponent } from './components/reports/trace-heating-status/trace-heating-status-print/trace-heating-status-print.component';
import { ModelShotsComponent } from './components/reports/trace-heating-status/model-shots/model-shots.component';
import { MultipleAttachmentsComponent } from './components/reports/trace-heating-status/multiple-attachments/multiple-attachments.component';
import { ScExcludeConfigurationComponent } from './components/weekly-planning/sc-exclude-configuration/sc-exclude-configuration.component';
import { ScExcludeSubsystemConfigurationComponent } from './components/weekly-planning/sc-exclude-configuration/sc-exclude-subsystem-configuration/sc-exclude-subsystem-configuration.component';
import { ExcludeFilterAssignmentComponent } from './components/weekly-planning/sc-exclude-configuration/exclude-filter-assignment/exclude-filter-assignment.component';
import { ScExcludeItrConfigurationComponent } from './components/weekly-planning/sc-exclude-configuration/sc-exclude-itr-configuration/sc-exclude-itr-configuration.component';
import { MatSliderModule } from '@angular/material/slider';
import { DcnRfiRedlineRegisterComponent } from './components/dcn-rfi-redline-register/dcn-rfi-redline-register.component';
import { RegisterFilterComponent } from './components/dcn-rfi-redline-register/register-filter/register-filter.component';
import { DcnRfiFilterComponent } from './components/dcn-rfi-redline-register/register-filter/dcn-rfi-filter/dcn-rfi-filter.component';
import { RedlineFilterComponent } from './components/dcn-rfi-redline-register/register-filter/redline-filter/redline-filter.component';
import { RegisterFilterTopHeaderComponent } from './components/dcn-rfi-redline-register/register-filter/register-filter-top-header/register-filter-top-header.component';
import { DoubleScrollDirective } from './directives/double-scroll';
import { DcnRfiRedlineTableComponent } from './components/dcn-rfi-redline-register/dcn-rfi-redline-table/dcn-rfi-redline-table.component';
import { DcnRfiDetailsComponent } from './components/dcn-rfi-redline-register/details/dcn-rfi-details/dcn-rfi-details.component';
import { PipelineComponent } from './components/admin-section/automatic-upload/pipeline/pipeline.component';
import { OverdueExceptionsByPriorityComponent } from './components/reports/health/overdue-exceptions-by-priority/overdue-exceptions-by-priority.component';
import { OverdueExceptionsByPriorityFilterComponent } from './components/reports/health/overdue-exceptions-by-priority/overdue-exceptions-by-priority-filter/overdue-exceptions-by-priority-filter.component';
import { OverdueExceptionsByPriorityChartComponent } from './components/reports/health/overdue-exceptions-by-priority/overdue-exceptions-by-priority-chart/overdue-exceptions-by-priority-chart.component';
import { OverdueExceptionsByPriorityTableComponent } from './components/reports/health/overdue-exceptions-by-priority/overdue-exceptions-by-priority-table/overdue-exceptions-by-priority-table.component';
import { ActivitiesConstraintsComponent } from './components/reports/health/activities-constraints/activities-constraints.component';
import { ActivitiesConstraintsChartComponent } from './components/reports/health/activities-constraints/activities-constraints-chart/activities-constraints-chart.component';
import { ActivitiesConstraintsFilterComponent } from './components/reports/health/activities-constraints/activities-constraints-filter/activities-constraints-filter.component';
import { WalkdownCommitmentDisabledPipe } from './pipes/walkdown-commitment.pipe';
import { SubsystemExceptionsTableComponent } from './components/reports/health/overdue-exceptions-by-priority/subsystem-exceptions-table/subsystem-exceptions-table.component';
import { PLIPlanningComponent } from './components/weekly-planning/pli-planning/pli-planning.component';
import { PLIPlanningFilterComponent } from './components/weekly-planning/pli-planning/pli-planning-filter/pli-planning-filter.component';
import { PLIPlanningTableComponent } from './components/weekly-planning/pli-planning/pli-planning-table/pli-planning-table.component';
import { PowerBIReportsComponent } from './components/reports/power-bi';
import { FieldImplementationContractorHistoryComponent } from './components/change-management/field-implementation-contractor-history/field-implementation-contractor-history.component';
import { ITRConstraintsComponent } from './components/weekly-planning/planning-upload/itr-constraints/itr-constraints.component';
import { ResponsbilePersonSelectorComponent } from './components/detailed-status/responsbile-person-selector/responsbile-person-selector.component';
import { ConstraintTypeComponent } from './components/admin-section/scmt/constraint-type/constraint-type.component';
import { PASCRComponent } from './components/pascr-status/pascr-status.component';
import { StatusUpdateComponent } from './components/admin-section/automatic-upload/status-update/status-update.component';
import { ConstraintsContactsComponent } from './components/weekly-planning/planning-upload/itr-constraints-contacts/itr-constraints-contacts.component';
import { CITRPartialProgressComponent } from './components/weekly-planning/planning-upload/citr-partial-progress/citr-partial-progress.component';
import { CriticalPathComponent } from './components/weekly-planning/planning-upload/critical-path/critical-path.component';
import { ExceptionsUploadComponent } from './components/weekly-planning/planning-upload/exceptions-upload/exceptions-upload.component';
import { PriorityNumbersComponent } from './components/admin-section/automatic-upload/priority-numbers/priority-numbers.component';
import { CriticalRfiComponent } from './components/critical-rfi/critical-rfi.component';
import { LeakTestPackTrackerSearchComponent } from './components/leak-test-pack-tracker-search/leak-test-pack-tracker-search.component';
import { PliImplementationTeamComponent } from './components/admin-section/scmt/pli-implementation-team/pli-implementation-team.component';
import { PLIDataUploadComponent } from './components/weekly-planning/planning-upload/pli-data-upload/pli-data-upload.component';
import { ITRCommentUploadComponent } from './components/weekly-planning/planning-upload/itr-comment-upload/itr-comment-upload.component';
import { RFODatesUploadComponent } from './components/weekly-planning/planning-upload/rfo-dates-upload/rfo-dates-upload.component';
import { ResponsibleGroupComponent } from './components/admin-section/scmt/responsible-group/responsible-group.component';
import { ManualPriroityHistoryComponent } from './components/critical-rfi/manual-priority-history/manual-priority-history.component';
import { LtpPriorityHistoryComponent } from './components/leak-test-pack-tracker-search/ltp-priority-history/ltp-priority-history.component';
import { EtcHistoryComponent } from './components/critical-rfi/etc-history/etc-history.component';
import { DetailedStatusEtcHistoryComponent} from './components/detailed-status/etc-history/etc-history.component';
import { CommentsHistoryWithoutDeleteComponent } from './components/comments-history-without-deletebutton/comments-history-without-delete.component';
import { DataUpdateHistoryComponent } from './components/data-update-history/data-update-history.component';
import { SubsystemReadyForSignOffPopupComponent } from './components/validation-form/subsystem-ready-for-sign-off-popup/subsystem-ready-for-sign-off-popup';

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
};

@NgModule({
    declarations: [
        AppComponent,
        TopHeaderComponent,
        FooterComponent,
        NavbarComponent,
        NotFoundComponent,
        NotAuthorizedComponent,
        UserSynchronizationComponent,
        RedZoneComponent,
        BlueZoneComponent,
        RFOComponent,
        DetailedStatusComponent,
        ConfirmDialogComponent,
        UserDetailsComponent,
        DetailsComponent,
        ChangeManagementComponent,
        ChangeDocumentComponent,
        DashboardComponent,
        AdminComponent,
        BreakLinePipe,
        AttachmentsPipe,
        ManualUploadComponent,
        PlanningUploadComponent,
        MasterSubsystemListComponent,
        CWPRegisterComponent,
        PlanningDataComponent,
        ScheduleActivityComponent,
        ActivityBarcodeComponent,
        ExceptionsPriorityComponent,
        CriticalPathComponent,
        CITRPartialProgressComponent,
        ExceptionsUploadComponent,
        ITRManhoursComponent,
        SubsystemConflictsComponent,
        ChangeDocumentDetailsComponent,
        AutomaticUploadComponent,
        ExceptionComponent,
        CertificateComponent,
        PipelineComponent,
        StatusUpdateComponent,
        ITRComponent,
        PLIComponent,
        TagComponent,
        FieldChangeSummaryComponent,
        SignOffPopupComponent,
        ChangeValidationComponent,
        ValidationFormComponent,
        HistoryPopupComponent,
        DataMaintenanceComponent,
        AssignProjectTeamPopupComponent,
        WeeklyPlanningComponent,
        WeeklyPlanningDashboardComponent,
        WeeklyPlanningSessionComponent,
        WeeklyPlanningSessionFilterComponent,
        WeeklyPlanningSessionTableComponent,
        WeeklyPlanningSessionMCComponent,
        WeeklyPlanningSessionRFSUComponent,
        WeeklyPlanningSessionRFOComponent,
        WeeklyPlanningPastWeekComponent,
        WeeklyPlanningCommitmentsComponenet,
        WeeklyPlanningDailyComponent,
        DataMaintenanceComponent,
        TeamDataComponent,
        SubsystemMaintenanceComponent,
        ProjectTeamMaintenanceComponent,
        AddContractorPopupComponent,
        AddProjectTeamRolePopupComponent,
        ContractorsMaintenanceComponent,
        CommentsHistoryComponent,
        CommentsHistoryWithoutDeleteComponent,
        ProjectTeamRolesMaintenanceComponent,
        ReportsComponent,
        ReportsDashboardComponent,
        ReportsSkylineComponent,
        ReportsSkylineChartComponent,
        UploadDcnImpactedTagsComponent,
        ImpactedTagsPopupComponent,
        ReportsPlanningLookaheadComponent,
        ReportsPlanningLookaheadContentComponent,
        ReportsPlanningLookaheadChartComponent,
        ReportsPlanningLookaheadTableComponent,
        ReportsRundownComponent,
        ReportsRundownContentComponent,
        ReportsRundownChartComponent,
        ReportsPlanningCommitmentReliabilityComponent,
        ReportsPlanningCommitmentReliabilityContentComponent,
        ReportsPlanningCommitmentReliabilityChartComponent,
        ReportsPlanningCommitmentReliabilityTableComponent,
        ReportsHealthCleanlinessComponent,
        ReportsHealthCleanlinessContentComponent,
        ReportsHealthCleanlinessChartComponent,
        ReportsHealthCleanlinessTableComponent,
        ColResizeDirective,
        TopHeaderTableComponent,
        ConfigurationComponent,
        FtpConfigurationComponent,
        GeneralAppConfigurationComponent,
        IcicleChartComponent,
        IcicleReportComponent,
        IcicleLineSeriesComponent,
        IcicleCircleSeriesComponent,
        AddContractorMappingPopupComponent,
        ContractorsMappingsComponent,
        IcicleTooltipArea,
        AddProjectTeamPopupComponent,
        GroupVerticalStackedComponent,
        MilestoneWaypointComponent,
        MilestoneMaintenanceComponent,
        WaypointMaintenanceComponent,
        AddMilestonePopupComponent,
        AddWaypointPopupComponent,
        TagSearchComponent,
        ClearableMatDatepickerComponent,
        IwpListingComponent,
        RFOImportComponent,
        ChangeValidationDashboardComponent,
        WeeklyValidationProgressComponent,
        MonthlyValidationProgressComponent,
        CumulativeValidationProgressComponent,
        WeeklyCumulativeValidationProgressComponent,
        ValidationProgressComponent,
        SystemisationProgressComponent,
        MonthlyChangeRaisedComponent,
        WeeklyChangeRaisedComponent,
        ValidatorPerformanceScoreboardComponent,
        MilestoneDashboardComponent,
        GOOC1Component,
        GOOC2Component,
        TableTotalCompleteComponent,
        IcicleChartMainComponent,
        ClearableMatDatepickerComponent,
        RundownCurvesComponent,
        ProgressBarDirective,
        MatSelectRemoveAriaOwnsDirective,
        IwpPopupComponent,
        EnumKeyValueListPipe,
        AddNewChangeComponent,
        MCPlusComponent,
        SkylinePrintComponent,
        SkylineSimpleComponent,
        FilterHiddenPipe,
        ContractorListPipe,
        UserListPipe,
        UserToContractsComponent,
        AddUserToContractsPopupComponent,
        EscapeHtmlPipe,
        IMDBComponent,
        DragAndDropDirective,
        FormatMentionsPipe,
        MyPageComponent,
        ExportPageComponent,
        SystemizedDrawingsComponent,
        SubsystemMCDashboardComponent,
        SubsystemMCDashboard2Component,
        ReplaceCharPipe,
        NoBreakingDashPipe,
        LoopComponent,
        LoopStatusComponent,
        SystemisedDrawingComponent,
        COWListComponent,
        GoocPreMCComponent,
        EarlyWorksComponent,
        EarlyWalkdownsComponent,
        CertificationStatusComponent,
        SOCPlanComponent,
        PreMCSkylineComponent,
        PreMCScheduleComponent,
        MCScheduleComponent,
        MCSkylineComponent,
        RFSUSkylineComponent,
        MCLookaheadComponent,
        DailyPlanningSessionFilterComponent,
        MCSubSystemTableComponent,
        SkylineLegendComponent,
        DeleteSignOffPopupComponent,
        CTAFormComponent,
        CTARegisterComponent,
        OverdueDPLIComponent,
        CertificateSearchComponent,
        FlangeManagementComponent,
        RedlineComponent,
        RFSULookaheadComponent,
        ChangeManagementManualComponent,
        ConfigurationPanelComponent,
        DesignStatusMappingComponent,
        ImplementationStatusMappingComponent,
        AddDesignStatusMappingComponent,
        AddImplementationStatusMappingComponent,
        ContractorMappingComponent,
        AddContractorMappingComponent,
        AutomatedFileTransfersMcplusComponent,
        DisciplineMappingComponent,
        ProjectTeamMappingComponent,
        ConfigurationPanelMCplusComponent,
        AddDisciplineMappingComponent,
        AddProjectTeamMappingComponent,
        SourceFileConfigurationComponent,
        HomeComponent,
        SubsystemRfsuDashboardComponent,
        ReportsRundownTableComponent,
        ScheduleActivityComponent,
        ScheduleActivityPlanningComponent,
        ScheduleActivityPlanningTableComponent,
        ScheduleActivityPlanningSubsystemTableComponent,
        SCWorkAreaConfigurationComponent,
        ScPlanningConfigurationComponent,
        ConstraintConfigurationComponent,
        AddScheduleActivityConstraintComponent,
        AutomatedFileTransfersOtherSourcesComponent,
        ConstraintTypeSelectorPopupComponent,
        ITRConstraintTypeSelectorPopupComponent,
        CleanlinessConfigurationComponent,
        ForecastHistoryComponent,
        ScheduleActivityItrComponent,
        ScheduleActivityLookaheadTableComponent,
        CreateActivityComponent,
        ScheduleActivityPrintComponent,
        AddSubsystemComponent,
        ChipListComponent,
        LookaheadWorkAreaFilterComponent,
        LookaheadActivitiesFilterComponent,
        LookaheadFilterComponent,
        LookaheadFilterCommonSectionComponent,
        LookaheadFilterTopHeaderSectionComponent,
        WeekSummaryComponent,
        MatTableStickyHeaderComponent,
        BacklogCategoriesConfigurationComponent,
        OverdueExceptionsComponent,
        OverdueExceptionsFilterComponent,
        OverdueExceptionsChartComponent,
        OverdueExceptionsTableComponent,
        CustomGroupedVerticalStackedChartComponent,
        ExcelExportListComponent,
        ScPriorityConfigurationComponent,
        ConstraintflagComponent,
        TimeTriggersConfigurationComponent,
        AutomaticUploadsConfigurationComponent,
        TraceHeatingStatusComponent,
        TraceHeatingStatusTableComponent,
        TraceHeatingStatusPrintComponent,
        ModelShotsComponent,
        MultipleAttachmentsComponent,
        ScExcludeConfigurationComponent,
        ScExcludeSubsystemConfigurationComponent,
        ScExcludeItrConfigurationComponent,
        ExcludeFilterAssignmentComponent,
        DcnRfiRedlineRegisterComponent,
        RegisterFilterComponent,
        DcnRfiFilterComponent,
        RedlineFilterComponent,
        RegisterFilterTopHeaderComponent,
        DoubleScrollDirective,
        DcnRfiRedlineTableComponent,
        DcnRfiDetailsComponent,
        OverdueExceptionsByPriorityComponent,
        OverdueExceptionsByPriorityFilterComponent,
        OverdueExceptionsByPriorityChartComponent,
        OverdueExceptionsByPriorityTableComponent,
        ActivitiesConstraintsComponent,
        ActivitiesConstraintsChartComponent,
        ActivitiesConstraintsFilterComponent,
        WalkdownCommitmentDisabledPipe,
        SubsystemExceptionsTableComponent,
        PLIPlanningComponent,
        PLIPlanningFilterComponent,
        PLIPlanningTableComponent,
        PowerBIReportsComponent,
        FieldImplementationContractorHistoryComponent,
        ITRConstraintsComponent,
        ConstraintsContactsComponent,
        ConstraintTypeComponent,
        ResponsbilePersonSelectorComponent,
        PASCRComponent,
        PriorityNumbersComponent,
        CriticalRfiComponent,
        LeakTestPackTrackerSearchComponent,
        PliImplementationTeamComponent,
        PLIDataUploadComponent,
        ITRCommentUploadComponent,
        RFODatesUploadComponent,
        ResponsibleGroupComponent,
        ManualPriroityHistoryComponent,
        LtpPriorityHistoryComponent,
        EtcHistoryComponent,
        DataUpdateHistoryComponent,
        SubsystemReadyForSignOffPopupComponent,
        DetailedStatusEtcHistoryComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        StoreModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMenuModule,
        FormsModule,
        MatRadioModule,
        MatTabsModule,
        AppRoutingModule,
        MatListModule,
        AngularStickyThingsModule,
        MatToolbarModule,
        PopoverModule,
        NgxChartsModule,
        SharedModule,
        MatSlideToggleModule,
        MatSliderModule,
        NgxPrintModule,
        PortalModule,
        MsalModule,
        ResizableModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: appConfig.production,
            registrationStrategy: 'registerImmediately',
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        PopupService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInscanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        ProjectTeamsService,
        RoleService,
        UpdateService,
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: APP_INSIGHTS_CONFIG, useValue: appInsightsConfig },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        PendingChangesGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
